
import { defineComponent } from "vue";
import { Form } from "vee-validate";
import ElCurrencyInputNoSymbol from "@/components/financial/CurrencyInputNoSymbol.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "customer-financial-statement-Sales",
  components: {
    Form,
    ElCurrencyInputNoSymbol,
  },
  data() {
    const months = [
      "Jan",
      "Feb",
      "Mac",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const statements = {
      salesActivities: {
        label: "Sales Activities",
        total: 0,
        child: {
          salesGoodsTrading: {
            label: "Sales of Goods/Trading",
            value: [],
            total: 0,
          },
          servicesConsulting: {
            label: "Services/Consulting",
            value: [],
            total: 0,
          },
          others: {
            label: "Others",
            value: [],
            total: 0,
          },
        },
      },
      totalSales: {
        label: "Total",
        value: [],
        // total: 0,
      },
    };

    let isLoading = true;
    const route = useRoute();
    const store = useStore();
    const cstmr_id = route.params.customer_id ?? null;
    const fin_id = route.params.fin_id ?? null;
    const customer = {
      birthdate: "",
      company_id: 0,
      detail: {},
      email: "",
      facilities: [],
      gender: "",
      media: [],
      name: "",
      nric: "",
      phone: "",
      package: "",
    };

    return {
      router: useRouter(),
      isLoading,
      months,
      statements,
      store,
      fin_id,
      cstmr_id,
      customer,
      finDetails: null,
      isFinReady: false,
    };
  },
  async mounted() {
    setCurrentPageBreadcrumbs("Sales", [
      "Customer",
      "Financial Statements SME",
      "Income Statements",
    ]);
    this.init();
    this.initMonths();
  },
  methods: {
    init() {
      if (this.cstmr_id) {
        this.store.dispatch(Actions.GET_CUSTOMER, this.cstmr_id).then(() => {
          this.customer = this.store.getters.getCustomerData;
          this.isLoading = false;
        });
      }

      if (this.fin_id) {
        this.store
          .dispatch(Actions.GET_FINANCIAL_STATEMENT, this.fin_id)
          .then(() => {
            this.statements = this.store.getters.getFinancialStatementData.raw.statements;
          });
      }
    },
    initMonths() {
      for (let index = 0; index < this.months.length; index++) {
        this.statements.totalSales.value.push(0);
      }

      for (let key of Object.keys(this.statements.salesActivities.child)) {
        let salesActivitiesChild = this.statements.salesActivities.child;
        for (let index = 0; index < this.months.length; index++) {
          salesActivitiesChild[key].value.push(0);
        }
      }
    },

    calculateTotal() {
      for (let i = 0; i < this.statements.totalSales.value.length; i++) {
        this.statements.totalSales.value[i] = 0;
      }

      let result = 0;
      for (let keyChild of Object.keys(this.statements.salesActivities.child)) {
        let salesActivitiesChild = this.statements.salesActivities.child[
          keyChild
        ];

        let stateChildValue = salesActivitiesChild.value;
        salesActivitiesChild.total = stateChildValue.reduce(
          (sum, item) => sum + item
        );

        result += salesActivitiesChild.total;
        this.statements.salesActivities.total = result;

        for (let i = 0; i < this.statements.totalSales.value.length; i++) {
          this.statements.totalSales.value[i] += stateChildValue[i];
        }
      }
    },

    onSubmit(value) {
      const data = {
        id: this.fin_id,
        user_id: this.cstmr_id,
        financial_statement_type_id: 6,
        raw: {
          statements: this.statements,
        },
      };

      if (this.fin_id) {
        this.store
          .dispatch(Actions.UPDATE_FINANCIAL_STATEMENT, data)
          .then(() => {
            this.swalMessage("updated");
          });
      } else {
        this.store
          .dispatch(Actions.STORE_FINANCIAL_STATEMENT, data)
          .then(() => {
            this.swalMessage("saved");
          });
      }
    },
    swalMessage(m) {
      Swal.fire({
        title: "Congratulation!",
        text: "Sales Statement has been " + m + "!",
        icon: "success",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "OK",
      }).then(() => {
        this.router.push({
          path: `/customers/details/${this.cstmr_id}/financial-statements/sme/list`,
        });
      });
    },
  },
});
